import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <div
    style={{
      height: `100vh`,
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`
    }}
  >
    <SEO title="Coming Soon" />
    <h1>Coming Soon</h1>
  </div>
)

export default IndexPage
